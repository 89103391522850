//! Reservation is the same as Booking
import { isValid, parseISO } from "date-fns";
import { ReservationStage } from "../utils/mrr/reservationConstants";
// import { ListingGeneral } from "./listingGeneral";
import { AccessCode } from "./AccessCode";
import { UserAccountGeneral } from "./UserAccountGeneral";


export enum Airlines {
    Air_Canada = "Air Canada",
    Alaska_Airlines = "Alaska Airlines",
    American_Airlines = "American Airlines",
    Delta = "Delta",
    Hawaiian_Airlines = "Hawaiian Airlines",
    Island_Air = "Island Air",
    Mokulele_Airlines = "Mokulele Airlines",
    United = "United",
    Virgin_America = "Virgin America",
    WestJet = "WestJet",
}

export const AirlinesOptions = [
    Airlines.Air_Canada,
    Airlines.Alaska_Airlines,
    Airlines.American_Airlines,
    Airlines.Delta,
    Airlines.Hawaiian_Airlines,
    Airlines.Island_Air,
    Airlines.Mokulele_Airlines,
    Airlines.United,
    Airlines.Virgin_America,
    Airlines.WestJet,
]

export interface EditReservationFormI {
    id: string;
    listing_id: string;
    guest_id: string;
    check_in?: Date;
    check_out?: Date;
}

export enum ReservationType {
    //! These are reservation types in the external model.
    Lessee = 'Lessee',
    GuestOccupancy = 'Guest Occupancy',
    OwnerOccupancy = 'Owner Occupancy',
    Maintenance = 'Maintenance',
    Housekeeping = 'Housekeeping',
    External = 'External',
    Internal = 'Internal',
    Orphan = 'Orphan',
    //! These are from the API for type and next_check_in_type.
    Owner = 'Owner',
    GuestOfOwner = 'Guest of Owner',
    Guest = 'Guest'
}

// NOTE: Keep in mind that Reservation type and guest_type can be remapped by the backend!
//      You will not necessarily get a value from the SF picklist.
//
//      The model field 'guest_type' derives from the reservation's type (with exceptions for
//      Maintenance and disaster relief), so checking:
//        (row.type === ReservationType.Owner || row.type === ReservationType.GuestOfOwner)
//      ...is often the literal equivalent of checking:
//        (row.guest_type === ReservationGuestType.Owner || row.guest_type === ReservationGuestType.OwnerGuest)

export enum ReservationGuestType {
    // Guest Types original
    Owner = 'Owner',
    OwnerGuest = 'Owner Guest',
    Guest = 'Guest',
    Other = 'Other',

    // Guest Types overridden when there is a disaster type
    ReliefWorker = 'Relief Worker',
    Survivor = 'Survivor',
    MRREmployeeSurvivor = 'MRR Employee Survivor',
    MRROhanaSurvivor = 'MRR Ohana Survivor',
    NoShow = 'No Show'
}

export enum CheckInRequest {
    SPLIT_BED = 'Split Bed',
    PACK_AND_PLAY = 'Pack & Play',
    HIGH_CHAIR = 'High Chair',
    BOOSTER = 'Booster',
    FUTON = 'Futon',
}
export enum ReadOnlyCheckInRequest {
    Cooler = 'Cooler',
    Speaker = 'Speaker',
    Beach_Wagon = 'Beach Wagon',
    Spike_Ball = 'Spike Ball',
    Champagne = 'Champagne',
    Cookies = 'Cookies',
    Oishii_Party_Mix = 'Oishii Party Mix',
    Frisbee = 'Frisbee',
    Cornhole = 'Cornhole',
}

export enum ReservationTimeMode {
    Past = 'past',
    InHouse = 'inhouse',
    Future = 'future'
}

export const CheckInRequestOptions = Object.values(CheckInRequest)

export class ReservationGeneral {
    public readonly sortable_name: string;
    public readonly stage_mapped: string;
    public readonly booking_date_ISO: Date;
    public readonly check_in_ISO: Date;
    public readonly check_out_ISO: Date;
    public readonly next_check_in_ISO: Date;
    public readonly ui_table_guest_name: string;
    public readonly departure_flight_time_ISO: Date | null;
    public readonly arrival_flight_time_ISO: Date | null;
    public readonly check_in_requests: CheckInRequest[] = [];
    public readonly check_in_requests_detail: string | null = null;
    public readonly travel_insurance_deadline_ISO: Date | null = null;
    public readonly balance_due_date_ISO: Date | null = null;

    constructor(
        public readonly id: string,
        public readonly arrival_flight_number: string,
        public readonly back_to_back: boolean,
        public readonly bathrooms: number,
        public readonly bedrooms: number,
        public readonly check_in_time: string,
        public readonly check_in_date: string,
        public readonly check_in_gap: number,
        public readonly check_out_time: string,
        public readonly check_out_date: string,
        public readonly check_out_gap: number,
        public readonly departure_flight_number: string,
        public readonly estimated_travel_insurance_amount: number,
        public readonly guest_id: string,
        public readonly guest_is_owner: boolean,
        public readonly guest_name: string,
        public readonly guest_type: ReservationGuestType,
        public readonly has_check_in_requests: boolean,
        public readonly has_travel_insurance: boolean,
        public readonly hybrid_parent_id: string,
        public readonly hybrid_parent_name: string,
        public readonly is_host: boolean,
        public readonly is_hybrid_child: boolean,
        public readonly is_hybrid_parent: boolean,
        public readonly is_inherited: boolean,
        public readonly is_timeshare: boolean,
        public readonly is_future_booking: boolean,
        public readonly is_current_booking: boolean,
        public readonly is_past_booking: boolean,
        public readonly listing_id: string,
        public readonly late_check_out_amount: number,
        public readonly late_check_out_allowed: false,
        public readonly max_check_out_time: string,
        public readonly name_detailed: string,
        public readonly name: string,
        public readonly next_check_in_type: string,
        public readonly nightly_rate: number,
        public readonly nights: number,
        public readonly owner_pays_guest_clean: boolean,
        public readonly previous_check_out: string,
        public readonly rental_amount: number,
        public readonly split_beds: boolean,
        public readonly split_bed_allowed: boolean,
        public readonly travel_insurance_eligible: boolean,
        public readonly travel_insurance_vendor: string,
        public readonly turnaround: boolean,
        public readonly type: string,
        public readonly unit_id: string,
        public readonly booking_channel: string,
        public readonly total_amount: number,
        public readonly payment_amount: number,
        public readonly balance_due: number,
        public readonly cancellation_policy: string,
        public readonly external_revenue: boolean,
        public readonly insurance_policy_id: string,
        public readonly unit_view: string,
        public readonly listing_photo_url: string,
        public readonly arrival_flight_time: string, // 2023-10-31
        public readonly departure_flight_time: string, // "2023-11-04"
        public readonly arrival_time: string, // 4:00 PM
        public readonly departure_time: string, // 4:00 PM
        public readonly access_codes: AccessCode[],
        public readonly has_late_check_out: boolean,
        public readonly extend_stay_allowed: boolean,
        public readonly wifi_password: string,
        public readonly wifi_network: string,
        public readonly is_balance_past_due: boolean,
        public readonly listing: ReservationListing | null,
        public readonly hide_financials: boolean,
        public readonly vehicle_license: string,
        public readonly has_stay_reason: boolean,
        public readonly stay_reason: string,
        public readonly stay_reason_detail: string,
        public readonly ra_signed: boolean,
        public readonly require_signed_ra: boolean,
        public readonly guest_registration_requirements: string[],
        public readonly guest_registration_complete: boolean,
        public readonly guest_registration_completed: string[],
        public readonly require_guest_registration: boolean,
        public readonly minimum_age: number,
        public readonly max_guests: number,
        public readonly host_verified: boolean,
        public readonly rental_agreement_id: string,
        public readonly pet_fee: number,
        public readonly max_pets: number,
        public readonly pet_count: number,
        public readonly pet_policy: string,
        private readonly _balance_due_date: string,
        private readonly _booking_date: string,
        private readonly _check_in_requests_detail: string,
        private readonly _check_in_requests: string,
        private readonly _check_in: string,
        private readonly _check_out: string,
        private readonly _next_check_in: string,
        private readonly _stage: string,
        private readonly _travel_insurance_deadline: string,
        private _verification_code: string
    ) {
        this.sortable_name = this.name.toLowerCase();
        this.stage_mapped = ReservationGeneral.MapStageToEnum(this._stage);
        // this.listing_status_mapped = ListingGeneral.MapStatusToEnum(this.listing_status);
        this.booking_date_ISO = parseISO(this._booking_date)
        this.check_in_ISO = parseISO(this._check_in)
        this.check_out_ISO = parseISO(this._check_out)
        this.next_check_in_ISO = parseISO(this._next_check_in)
        if (this.has_check_in_requests && typeof this._check_in_requests === 'string') {
            this.check_in_requests = this._check_in_requests.split(';') as CheckInRequest[]
        }
        if (this._check_in_requests_detail) {
            this.check_in_requests_detail = this._check_in_requests_detail
        }

        this.ui_table_guest_name = this.type === ReservationType.Maintenance ? ReservationType.Maintenance : this.guest_name

        // Flight Infomation
        if (isValid(new Date(this.departure_flight_time))) {
            this.departure_flight_time_ISO = parseISO(this.departure_flight_time)
        } else {
            this.departure_flight_time_ISO = null
        }
        if (isValid(new Date(this.arrival_flight_time))) {
            this.arrival_flight_time_ISO = parseISO(this.arrival_flight_time)
        } else {
            this.arrival_flight_time_ISO = null
        }

        if (isValid(new Date(this._travel_insurance_deadline))) {
            this.travel_insurance_deadline_ISO = parseISO(this._travel_insurance_deadline)
        }

        if (isValid(new Date(this._balance_due_date))) {
            this.balance_due_date_ISO = parseISO(this._balance_due_date)
        }
    }

    public get verification_code(): string {
        return this._verification_code;
    }

    public set verification_code(value: string) {
        this._verification_code = value;
    }

    private static MapStageToEnum(stageIn: string): ReservationStage {
        const lowerCased = stageIn.toLowerCase();

        switch (lowerCased) {
            case 'confirmed':
            case 'closed won': {
                return ReservationStage.Confirmed;
            }

            // case 'quote':
            // case 'pending': {
            //     return ReservationStage.Pending;
            // }

            case 'closed lost': {
                // case 'closed nonrefundable':
                // case 'closed rescheduled':
                return ReservationStage.Canceled;
            }

            default: {
                console.warn(`unknown reservation stage: ${lowerCased} as "${stageIn}"`);
                return ReservationStage.Internal_Unknown;
            }
        }
    }
};


export function InstantiateReservationFromJSON(jsonRecord: any) {
    const access_codes: AccessCode[] = Array.isArray(jsonRecord.access_codes) ? jsonRecord.access_codes.map((accessCode: any) => new AccessCode(jsonRecord.name_detailed, accessCode.name, accessCode.instructions, accessCode.access_code)) : []
    const listing = jsonRecord.listing ? InstantiateReservationListingFromJSON(jsonRecord.listing) : null
    const newModel = new ReservationGeneral(
        jsonRecord.id,
        jsonRecord.arrival_flight_number,
        jsonRecord.back_to_back,
        jsonRecord.bathrooms,
        jsonRecord.bedrooms,
        jsonRecord.check_in_time,
        jsonRecord.check_in_date,
        jsonRecord.check_in_gap,
        jsonRecord.check_out_time,
        jsonRecord.check_out_date,
        jsonRecord.check_out_gap,
        jsonRecord.departure_flight_number,
        jsonRecord.estimated_travel_insurance_amount,
        jsonRecord.guest_id,
        jsonRecord.guest_is_owner,
        jsonRecord.guest_name,
        jsonRecord.guest_type,
        jsonRecord.has_check_in_requests,
        jsonRecord.has_travel_insurance,
        jsonRecord.hybrid_parent_id,
        jsonRecord.hybrid_parent_name,
        jsonRecord.is_host,
        jsonRecord.is_hybrid_child,
        jsonRecord.is_hybrid_parent,
        jsonRecord.is_inherited,
        jsonRecord.is_timeshare,
        jsonRecord.is_future_booking,
        jsonRecord.is_current_booking,
        jsonRecord.is_past_booking,
        jsonRecord.listing_id,
        jsonRecord.late_check_out_amount,
        jsonRecord.late_check_out_allowed,
        jsonRecord.max_check_out_time,
        jsonRecord.name_detailed,
        jsonRecord.name,
        jsonRecord.next_check_in_type,
        jsonRecord.nightly_rate,
        jsonRecord.nights,
        jsonRecord.owner_pays_guest_clean,
        jsonRecord.previous_check_out,
        jsonRecord.rental_amount,
        jsonRecord.split_beds,
        jsonRecord.split_bed_allowed,
        jsonRecord.travel_insurance_eligible,
        jsonRecord.travel_insurance_vendor,
        jsonRecord.turnaround,
        jsonRecord.type,
        jsonRecord.unit_id,
        jsonRecord.booking_channel,
        jsonRecord.total_amount,
        jsonRecord.payment_amount,
        jsonRecord.balance_due,
        jsonRecord.cancellation_policy,
        jsonRecord.external_revenue,
        jsonRecord.insurance_policy_id,
        jsonRecord.unit_view,
        jsonRecord.listing_photo_url,
        jsonRecord.arrival_flight_time,
        jsonRecord.departure_flight_time,
        jsonRecord.arrival_time,
        jsonRecord.departure_time,
        access_codes,
        jsonRecord.has_late_check_out,
        jsonRecord.extend_stay_allowed,
        jsonRecord.wifi_password,
        jsonRecord.wifi_network,
        jsonRecord.is_balance_past_due,
        listing,
        jsonRecord.hide_financials,
        jsonRecord.vehicle_license,
        jsonRecord.has_stay_reason,
        jsonRecord.stay_reason,
        jsonRecord.stay_reason_detail,
        jsonRecord.ra_signed,
        jsonRecord.require_signed_ra,
        jsonRecord.guest_registration_requirements,
        jsonRecord.guest_registration_complete,
        jsonRecord.guest_registration_completed,
        jsonRecord.require_guest_registration,
        jsonRecord.minimum_age,
        jsonRecord.max_guests,
        jsonRecord.host_verified,
        jsonRecord.rental_agreement_id,
        jsonRecord.pet_fee,
        jsonRecord.max_pets,
        jsonRecord.pet_count ? jsonRecord.pet_count : 0,
        jsonRecord.pet_policy,
        jsonRecord.balance_due_date,
        jsonRecord.booking_date,
        jsonRecord.check_in_requests_detail,
        jsonRecord.check_in_requests,
        jsonRecord.check_in,
        jsonRecord.check_out,
        jsonRecord.next_check_in,
        jsonRecord.stage,
        jsonRecord.travel_insurance_deadline,
        jsonRecord.verification_code
    );

    return newModel;
};

interface ListingAddress {
    street: string;
    state: string;
    postal_code: string;
    country: string;
    city: string;

}

class ReservationListing {
    public readonly next_check_out_ISO: Date | null;
    constructor(
        public readonly id: string,
        public readonly bathrooms: number,
        public readonly bedrooms: number,
        public readonly booking_url: string,
        public readonly latitude: number,
        public readonly longitude: number,
        public readonly listing_photo_url: string,
        public readonly name: string,
        public readonly name_detailed: string,
        public readonly resort_name: string,
        public readonly turnaround: boolean,
        public readonly type: string,
        public readonly unit_id: string,
        public readonly unit_view: string,
        public readonly address: ListingAddress,
        private readonly _next_check_out: string,
    ) {
        if (isValid(new Date(this._next_check_out))) {
            this.next_check_out_ISO = parseISO(this._next_check_out)
        } else {
            this.next_check_out_ISO = null
        }
    }
}

function InstantiateReservationListingFromJSON(jsonRecord: any) {
    return new ReservationListing(
        jsonRecord.id,
        jsonRecord.bathrooms,
        jsonRecord.bedrooms,
        jsonRecord.booking_url,
        jsonRecord.latitude,
        jsonRecord.longitude,
        jsonRecord.listing_photo_url,
        jsonRecord.name,
        jsonRecord.name_detailed,
        jsonRecord.resort_name,
        jsonRecord.turnaround,
        jsonRecord.type,
        jsonRecord.unit_id,
        jsonRecord.unit_view,
        jsonRecord.address,
        jsonRecord._next_check_out
    )
}