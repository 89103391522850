import { parseISO } from "date-fns"

export enum ListingStage {
    INPUTTED = 'Inputted',
    PENDING = 'Pending',
    CONFIRMED = 'Confirmed',
    LIVE = 'Live',
    TAKEOVER = 'Takeover',
    DORMANT = 'Dormant',
}

export enum ListingStatus {
    Clean = 'clean',
    Dirty = 'dirty',
    Occupied = 'occupied',
    Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

export enum ListingType {
    Condo = 'Condo',
    House = 'House',
    Villa = 'Villa',
    Internal_Unknown = 'unknown' //! This should never be displayed. It indicates a bad model.
};

export class ListingGeneral {
    public check_out_ISO: Date | null = null;
    public next_check_in_ISO: Date | null = null;

    constructor(
        public readonly id: string,
        public readonly bathrooms: number,
        public readonly bedrooms: number,
        public readonly booking_url: string, // YYYY-MM-DDT00:00:000Z
        private readonly _check_out: string, // YYYY-MM-DDT00:00:000Z
        public readonly latitude: number,
        public readonly listing_photo_url: string,
        public readonly listing_status: ListingStatus, // enum?
        public readonly longitude: number,
        public readonly name: string,
        public readonly name_detailed: string,
        private readonly _next_check_in: string, // YYYY-MM-DDT00:00:000Z
        public readonly resort_name: string,
        public readonly stage: ListingStage, // enum?
        public readonly turnaround: boolean,
        public readonly type: ListingType, // enum?
        public readonly unit_id: string,
        public readonly unit_view: string // enum?
    ) {
        if (typeof this._check_out === 'string' && this._check_out.endsWith('Z')) {
            this.check_out_ISO = parseISO(this._check_out);
        }

        if (typeof this._next_check_in === 'string' && this._next_check_in.endsWith('Z')) {
            this.next_check_in_ISO = parseISO(this._next_check_in);
        }
    }
}

export function InstantiateListingFromJSON(json: any) {
    return new ListingGeneral(
        json.id,
        json.bathrooms,
        json.bedrooms,
        json.booking_url,
        json.check_out,
        json.latitude,
        json.listing_photo_url,
        json.listing_status,
        json.longitude,
        json.name,
        json.name_detailed,
        json.next_check_in,
        json.resort_name,
        json.stage,
        json.turnaround,
        json.type,
        json.unit_id,
        json.unit_view
    )
}

// -------------------------------------------------------

export function mapStageToColor(stage: ListingStage) {
    switch (stage) {
        case ListingStage.CONFIRMED:
        case ListingStage.LIVE:
            return 'success';
        case ListingStage.DORMANT:
        case ListingStage.TAKEOVER:
            return 'warning';
        default:
            return 'primary';
    }
}

export function mapIconToListingType(type: ListingType) {
    switch (type) {
        case ListingType.Condo:
            return 'mdi:home-city-outline';
        case ListingType.Villa:
            return 'mdi:home-group';
        case ListingType.House:
            return 'mdi:home-outline';
        default:
            return 'mdi:home-roof'
    }
}