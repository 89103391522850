import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Button,
    Link,
    Stack,
    Typography
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { GuestGeneral } from "src/models/GuestGeneral";
import { ReservationGeneral } from "src/models/ReservationGeneral";
import { UserAccountGeneral } from "src/models/UserAccountGeneral";
import * as Yup from 'yup';
import FormProvider from "../../components/hook-form/FormProvider";
import { useGetSingleReservationQuery, usePatchRegistrationStepMutation, usePostIdentityVerificationMutation } from "../../redux/rtkQuery/apiSlice";
import { RegistrationPicklistItem } from "./types";


interface RegistrationIdentityFormProps {
    stepCompleted: boolean,
    stepFinal: boolean,
    reservation: ReservationGeneral,
    callbackRefreshReservation: (overrideBusy: boolean) => void,
    callbackCancel: VoidFunction,
    callbackContinue: VoidFunction,
    enforceSessionIsMain: boolean,
    mainGuest: GuestGeneral | null,
    sessionUser: UserAccountGeneral | null;
};

interface FormInputProps { };

const INITIATION_MAX_ATTEMPTS = 10;
const POLL_INTERVAL_MS = 15 * 1000;
const MAX_POLL_TIME = 5 * 60 * 1000;
const MAX_POLL_HITS = MAX_POLL_TIME / POLL_INTERVAL_MS;
const LOG_TAG = '[[VERIFY-ID POLL]] ';
const verboseLogging = false;

export function RegistrationIdentityForm({
    stepCompleted,
    stepFinal,
    reservation,
    callbackRefreshReservation,
    callbackCancel,
    callbackContinue,
    enforceSessionIsMain,
    mainGuest,
    sessionUser }: RegistrationIdentityFormProps
) {
    // const handleExpiredSession = useExpiredSessionErrorToNavigate();

    const [initiationAttempts, setInitiationAttempts] = useState<number>(0);
    const [verificationInitiated, setVerificationInitiated] = useState<boolean>(!!reservation.verification_code);
    const [userIdentityVerified, setUserIdentityVerified] = useState<boolean>(reservation.host_verified);
    const [localFetchCount, setLocalFetchCount] = useState<number>(0);

    const [pollExpired, setPollExpired] = useState<boolean>(false);
    const [pollHits, setPollHits] = useState<number>(0);
    const [pollRunning, setPollRunning] = useState<boolean>(false);
    const [pollTime, setPollTime] = useState<number>(0);
    const pollTimerId = useRef<NodeJS.Timeout | null>(null);

    // we use a local copy of the reservation fetch for the manual-refresh option
    const {
        isFetching: reservationFetching,
        isError: reservationError,
        isSuccess: reservationSuccess,
        data: reservationData,
        error: reservationErrorObject,
        refetch: refetchReservation
    } = useGetSingleReservationQuery({ reservationName: reservation.name }, {});

    const manuallyRefetchLocal = useCallback(() => {
        if (reservationFetching) {
            // already refetching
            return;
        }
        refetchReservation();
    }, [refetchReservation, reservationFetching]);

    const [sendPostIdentityVerification, {
        data: postIdentityVerificationData,
        isSuccess: postIdentityVerificationSuccess,
        isLoading: postIdentityVerificationLoading,
        isError: postIdentityVerificationError,
        error: postIdentityVerificationErrorData,
    }] = usePostIdentityVerificationMutation();

    const attemptVerificationInitiation = useCallback(async () => {
        if (postIdentityVerificationLoading) {
            // attempt already out; abort
            console.warn('redundant verification request');
            return;
        }
        if (initiationAttempts >= INITIATION_MAX_ATTEMPTS) {
            console.warn('verification request limit reached');
            return;
        }
        setInitiationAttempts((prev) => prev + 1);

        const result = await sendPostIdentityVerification({ reservationName: reservation.name });
        if ((result as any).error) {
            // we show a friendly message for these
        }
        else if ((result as any).data) {
            const code = (result as any).data.verification_code;
            if (code) {
                reservation.verification_code = code;
            }
            setVerificationInitiated(!!code);
        }
        else {
            console.warn('unknown response type');
        }
    }, [initiationAttempts, postIdentityVerificationLoading, reservation, sendPostIdentityVerification]);

    // timer to check the backend for account identity verification
    useEffect(() => {
        verboseLogging && console.log(LOG_TAG + 'EFFECT HIT');

        // local helper
        function cleanupTimer(reason: string) {
            verboseLogging && console.log(LOG_TAG + 'END ON ' + reason);
            if (pollTimerId.current === null) {
                throw new Error(LOG_TAG + 'invalid poll state; timer id null on cleanup: ' + reason);
            }
            clearInterval(pollTimerId.current);
            pollTimerId.current = null;
            setPollHits(0);
            setPollTime(0);
            setPollRunning(false);
        }
        let timerId: NodeJS.Timeout;
        if (pollRunning) {
            if (pollTimerId.current === null) {
                verboseLogging && console.log(LOG_TAG + 'CREATE TIMER');
                timerId = setInterval(() => {
                    verboseLogging && console.log(LOG_TAG + 'TICK');
                    setPollHits((prev) => prev + 1);
                    setPollTime((prev) => prev + POLL_INTERVAL_MS);

                    callbackRefreshReservation(true); // override busy
                }, POLL_INTERVAL_MS);
                verboseLogging && console.log(LOG_TAG + 'SET TIMER ID ' + timerId);
                pollTimerId.current = timerId;
            }
            else {
                verboseLogging && console.log(LOG_TAG + 'ALREADY RUNNING');
            }
        }
        else if (pollTimerId.current !== null) {
            cleanupTimer('STOPPED');
        }

        verboseLogging && console.log(LOG_TAG + 'DROP OUT');
        return () => {
            verboseLogging && console.log(LOG_TAG + 'UNSUBSCRIBE');
            if (pollTimerId.current) {
                verboseLogging && console.log(LOG_TAG + 'UNSUBSCRIBE');
                clearInterval(pollTimerId.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollRunning]);

    // a separate effect stops the timer, since its scope gets buried by React
    useEffect(() => {
        verboseLogging && console.log(LOG_TAG + 'END CHECK', pollRunning, pollHits);
        if (pollRunning && pollHits > MAX_POLL_HITS) {
            verboseLogging && console.log(LOG_TAG + 'MAX HITS DETECTED; ' + pollTimerId.current);
            setPollExpired(true);
            setPollRunning(false);
        }
    }, [pollHits, pollRunning]);

    // this checks the reservation through all refreshes to update 'verified' and 'verificationBegun' states
    useEffect(() => {
        if (!userIdentityVerified && reservation.host_verified) {
            verboseLogging && console.log('USER NOW VERIRFIED');
            setUserIdentityVerified(true);
        }
    }, [reservation, userIdentityVerified]);

    const resolver = useMemo(() => {
        return Yup.object().shape({});
    }, []);

    const formDefaults = {};

    const methods = useForm<FormInputProps>({
        defaultValues: formDefaults,
        resolver: yupResolver(resolver),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: {
            isDirty,
            isSubmitting,
            isValid,
            dirtyFields,
            errors
        },
        trigger,
        setError,
        getFieldState,
        setValue,
        watch,
    } = methods;

    const [
        sendPatchRegistrationStep,
        {
            isLoading: patchStepIsLoading,
            isError: patchStepIsError,
            error: patchStepError,
            reset: resetPatchStepMutation
        },
    ] = usePatchRegistrationStepMutation();

    // const { sendEvent } = useAnalyticsContext()

    const onSubmit = handleSubmit(async () => {
        if (stepCompleted) {
            callbackContinue();
            return;
        }

        const sfPayload = {
            reservationName: reservation.name,
            registrationStep: RegistrationPicklistItem.ID_VERIFICATION
        };

        await sendPatchRegistrationStep(sfPayload)
            .then((data: any) => {
                if (data.error) {
                    console.error(data.error);
                    return;
                }
                callbackContinue();
            })
            .catch((putError: any) => {
                console.error('error on update identity', putError);
            });

        //TODO
        // sendEvent(new GAEventGuest(
        //     sfPayload.type,
        //     sfPayload.is_minor,
        //     sfPayload.first_name,
        //     sfPayload.last_name,
        //     true,
        //     sfPayload.relation,
        //     reservation_name,
        // ))
    });

    const userInfoLoaded = mainGuest !== null && sessionUser !== null;

    const stepAllowedForUser = enforceSessionIsMain
        ? (userInfoLoaded && mainGuest.guest_id === sessionUser.externalAccountID)
        : true;

    // console.warn('userInfoLoaded', userInfoLoaded,
    //     'mainGuest', mainGuest?.id,
    //     'sessionUser', sessionUser?.id,
    //     'stepAllowedForUser', stepAllowedForUser,
    //     'enforceSessionIsMain', enforceSessionIsMain,
    //     'stepCompleted', stepCompleted,
    //     'userIdentityVerified', userIdentityVerified);

    if (stepAllowedForUser && !stepCompleted) {
        if (!verificationInitiated) {
            // we do not yet have a verification code; initiate the process
            if (!postIdentityVerificationLoading) {
                if (postIdentityVerificationError) {
                    // we show a friendly message for these
                }
                else if (!postIdentityVerificationSuccess) {
                    attemptVerificationInitiation();
                }
            }
            // else a call is out; just wait
        }
        else if (!userIdentityVerified) {
            // this user is allowed to verify identity...
            if (pollExpired) {
                // ...the poll ended naturally; no action
            }
            else if (!pollRunning) {
                // ...we should be polling; start the timer
                setPollRunning(true);
            }
        }
    }
    else if (pollRunning) {
        // no polling needed; stop the timer
        setPollRunning(false);
    }

    const codeURL = verificationInitiated
                        ? process.env.REACT_APP_GUEST_RANGER_VERIFY_ENDPOINT + '/' + reservation.verification_code
                        : '';

    const continueVerb = stepFinal ? 'Finish' : 'Continue';

    return (
        <FormProvider name="Identity Step Form" id={`Identity Step Form: ${reservation.name}`} methods={methods} onSubmit={onSubmit}>
            {/* This is a failsafe, in case of a fetch error higher up the wizard. */}
            {(enforceSessionIsMain && !userInfoLoaded) &&
                <Alert severity="error">
                    User info not loaded.
                </Alert>
            }

            {stepAllowedForUser
                ? <Stack spacing={2} mt={1} minHeight='300px'>
                    {(!userIdentityVerified && !stepCompleted) &&
                        <>
                        {postIdentityVerificationLoading
                        ?
                            <div>Beginning identity verification...</div>
                        :
                            <>
                            {verificationInitiated
                            ?
                                <>
                                    <Typography variant='subtitle1' textAlign='center'>
                                        A text message containing a link to verify your identity was sent to:
                                    </Typography>
                                    <Typography variant='subtitle1' textAlign='center' sx={{ bgcolor: 'rgba(0,0,0,0.1)', p: 2 }}>
                                        {mainGuest?.phone}
                                    </Typography>
                                    <Typography variant='body1' textAlign='center'>
                                        Please complete identity verification from your phone or you may go to <Link noWrap href={codeURL} target="_blank" rel="noopener">{codeURL}</Link>.
                                    </Typography>
                                    <Typography variant='body1' textAlign='center'>
                                        {!userIdentityVerified &&
                                            'Once verified, please click Check Status to continue.'
                                        }
                                    </Typography>
                                </>
                            :
                                <Alert severity="warning" style={{ fontSize: '1.1rem' }}>
                                    Unable to begin identity verification process. Please context Guest Experience for assistance.
                                </Alert>
                            }
                            </>
                        }
                        </>
                    }

                    {(!stepCompleted && userIdentityVerified) &&
                        <Alert severity="info" style={{ fontSize: '1.1rem' }}>
                            {'Identity verified. Please click \'' + continueVerb + '\'.'}
                        </Alert>
                    }

                    {(!stepCompleted && !userIdentityVerified && !reservationFetching && verificationInitiated && localFetchCount > 0) &&
                        <Alert severity="warning" style={{ fontSize: '1.1rem' }}>
                            Your identity has not yet been verified.
                        </Alert>
                    }
                </Stack>
                : <Alert severity="error">
                    You are not authorized to complete this step. Only the main host may verify identity.
                </Alert>
            }

            {/* Footer buttons */}
            <Stack direction='row' justifyContent='flex-end' width='100%' mt={4}>
                {/* NOTE: No back button. After the Rental Agreement is signed, user may not edit previous steps. */}
                <Stack direction='row' spacing={1}>
                    <Button
                        color="secondary"
                        disabled={stepCompleted || !stepAllowedForUser || userIdentityVerified || reservationFetching || postIdentityVerificationLoading}
                        variant="contained"
                        onClick={() => {
                            if (!verificationInitiated) {
                                // if we haven't yet initiated, the Guest Ranger call must have failed; try again
                                attemptVerificationInitiation();
                                return;
                            }
                            setLocalFetchCount(prev => prev + 1);
                            manuallyRefetchLocal();
                        }}
                    >
                        {verificationInitiated ? 'Check Status' : 'Try Again'}
                    </Button>
                    <LoadingButton
                        type="submit"
                        loading={patchStepIsLoading || postIdentityVerificationLoading}
                        disabled={(!stepAllowedForUser || !userIdentityVerified || postIdentityVerificationLoading) && !stepCompleted}
                        variant="contained"
                    >
                        {stepFinal ? 'Finish' : 'Continue'}
                    </LoadingButton>
                </Stack>
            </Stack>
        </FormProvider>
    );
}
