import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Stack } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardContentButton } from 'src/components/containers/CardContentButton';
import { GuestGeneral } from 'src/models/GuestGeneral';
import { ReservationGeneral } from 'src/models/ReservationGeneral';
import { UserAccountGeneral } from 'src/models/UserAccountGeneral';
import { SignaturePage } from 'src/routes/elements';
import useDownloadRA from 'src/utils/download-ra';
import * as Yup from 'yup';
import FormProvider from '../../components/hook-form/FormProvider';
import { usePatchRegistrationStepMutation } from '../../redux/rtkQuery/apiSlice';
import { RegistrationPicklistItem } from './types';
import DownloadRA from 'src/components/DownloadRA';

interface RegistrationRentalAgreementFormProps {
  stepCompleted: boolean;
  stepFinal: boolean;
  reservation: ReservationGeneral;
  callbackCancel: VoidFunction;
  callbackContinue: VoidFunction;
  enforceSessionIsMain: boolean;
  mainGuest: GuestGeneral | null;
  sessionUser: UserAccountGeneral | null;
}

interface FormInputProps {}

export function RegistrationRentalAgreementForm({
  stepCompleted,
  stepFinal,
  reservation,
  callbackCancel,
  callbackContinue,
  enforceSessionIsMain,
  mainGuest,
  sessionUser,
}: RegistrationRentalAgreementFormProps) {
  // const handleExpiredSession = useExpiredSessionErrorToNavigate();
  const agreementLoaded = useRef<boolean>(false);
  const [agreementSigned, setAgreementSigned] = useState<boolean>(stepCompleted);

  const resolver = useMemo(() => {
    return Yup.object().shape({});
  }, []);

  const formDefaults = {};

  const methods = useForm<FormInputProps>({
    defaultValues: formDefaults,
    resolver: yupResolver(resolver),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid, dirtyFields, errors },
    trigger,
    setError,
    getFieldState,
    setValue,
    watch,
  } = methods;

  const [
    sendPatchRegistrationStep,
    {
      isLoading: patchStepIsLoading,
      isError: patchStepIsError,
      error: patchStepError,
      reset: resetPatchStepMutation,
    },
  ] = usePatchRegistrationStepMutation();

  // const { sendEvent } = useAnalyticsContext()

  const onSubmit = handleSubmit(async () => {
    if (stepCompleted) {
      callbackContinue();
      return;
    }

    const sfPayload = {
      reservationName: reservation.name,
      registrationStep: RegistrationPicklistItem.RENTAL_AGREEMENT,
    };

    await sendPatchRegistrationStep(sfPayload)
      .then((data: any) => {
        if (data.error) {
          console.error(data.error);
          return;
        }
        callbackContinue();
      })
      .catch((putError: any) => {
        console.error('error on update rental agreement step', putError);
      });

    //TODO
    // sendEvent(new GAEventGuest(
    //     sfPayload.type,
    //     sfPayload.is_minor,
    //     sfPayload.first_name,
    //     sfPayload.last_name,
    //     true,
    //     sfPayload.relation,
    //     reservation_name,
    // ))
  });

  const userInfoLoaded = mainGuest !== null && sessionUser !== null;

  const stepAllowedForUser = enforceSessionIsMain
    ? userInfoLoaded && mainGuest.guest_id === sessionUser.externalAccountID
    : true;

  return (
    <FormProvider
      name="Rental Agreement Form"
      id={`Rental Agreement Form: ${reservation.name}`}
      methods={methods}
      onSubmit={onSubmit}
    >
      {/* This is a failsafe, in case of a fetch error higher up the wizard. */}
      {enforceSessionIsMain && !userInfoLoaded && (
        <>
          <Alert severity="error">Main host not found.</Alert>
          <br />
        </>
      )}

      {stepCompleted ? (
        <Stack>
          <Alert severity="info" style={{ fontSize: '1.1rem' }}>
            The rental agreement has been signed.
          </Alert>
          <br />
          {reservation.rental_agreement_id && (
            <DownloadRA raID={reservation.rental_agreement_id} reservationName={reservation.name} />
          )}
        </Stack>
      ) : stepAllowedForUser ? (
        <SignaturePage
          showTitle={false}
          formModeButtons
          customFooterElement
          customSubmitText={'Sign and ' + (stepFinal ? 'Finish' : 'Continue')}
          callbackAgreementLoaded={(loaded: boolean) => {
            agreementLoaded.current = loaded;
          }}
          callbackOnComplete={async () => {
            setAgreementSigned(true);

            // complete this registration step
            await onSubmit();
          }}
          callbackCancel={callbackCancel}
        />
      ) : (
        <Alert severity="error">
          You are not authorized to complete this step. Only the main host may sign the rental
          agreement.
        </Alert>
      )}

      {/* Footer buttons */}
      {/* NOTE: The PDF signer uses its own footer. This footer is for cases with
                      nothing to sign, either already-signed or signing-not-allowed.
            */}
      {(!stepAllowedForUser || agreementSigned) && (
        <Stack direction="row" justifyContent="space-between" width="100%" mt={4}>
          <Button disabled={isSubmitting} onClick={callbackCancel}>
            Back
          </Button>
          <LoadingButton
            type="submit"
            loading={patchStepIsLoading}
            disabled={(!stepAllowedForUser || !isValid || !agreementSigned) && !stepCompleted}
            variant="contained"
          >
            {/* TODO: Make this dynamic. For completed and final, we can just pass through the step spec */}
            {stepFinal ? 'Finish' : 'Continue'}
          </LoadingButton>
        </Stack>
      )}
    </FormProvider>
  );
}
